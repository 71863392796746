import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'

export class QrReader
  getCameras: ->
    return Html5Qrcode.getCameras()

  getCameraReader: ->
    return new Html5Qrcode(
      'qr-reader',
       formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE]
    )
